import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Icon = makeShortcode("Icon");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "design-and-user-experience",
      "style": {
        "position": "relative"
      }
    }}>{`Design and User Experience`}<a parentName="h1" {...{
        "href": "#design-and-user-experience",
        "aria-label": "design and user experience permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`There is an entire world of great design tools out there. Here, again, we aim to provide you with a highly curated list of tools that we use ourselves `}<em parentName="p">{`regularly`}</em>{` and which have made a profound impact on the way in which we work and the quality of what we are able to produce.`}</p>
    <h2 {...{
      "id": "case-studies--user-testing",
      "style": {
        "position": "relative"
      }
    }}>{`Case Studies & User Testing`}<a parentName="h2" {...{
        "href": "#case-studies--user-testing",
        "aria-label": "case studies  user testing permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Instead of linking you to Figma - which is the design tool we use ourselves - we have two things which are infinitely more useful, both given as gifts to us by Andrej from Deepwork Studios, to whom we are eternally grateful.`}</p>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Case Studies`}</strong></p>
        <p>{`Each of the studies which Deepwork have open-sourced here contain literally every aspect of their design process. You can find and fork all their Figma files, their Miro boards, their UX tests and prototypes. If you are a designer or UX Researcher, this is an actual, literal treasure trove.`}</p>
        <Button to="https://deepwork.studio/case-studies/" mdxType="Button">
    Explore
        </Button>
      </Flex>
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`User Testing Guide`}</strong>{` `}</p>
        <p>{`A genuinely useful, highly detailed, step-by-step guide for running fast, effective, and insightful user testing. There is no need to interview thousands of people - just `}<a parentName="p" {...{
            "href": "https://www.nngroup.com/articles/why-you-only-need-to-test-with-5-users/"
          }}>{`find five incentivized people`}</a>{`, listen to their feedback (especially the critical parts), and integrate it into your workflow through numerous iterations.`}</p>
        <Button to="https://www.notion.so/How-to-run-user-tests-fast-0e3ea190c9a34afabeb7eef9fdd7ef69" mdxType="Button">
    Test
        </Button>
      </Flex>
    </Column>
    <h2 {...{
      "id": "sketching-designs",
      "style": {
        "position": "relative"
      }
    }}>{`Sketching Designs`}<a parentName="h2" {...{
        "href": "#sketching-designs",
        "aria-label": "sketching designs permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We can't provide as much alpha here, but below are the six best tools we know and use for quick sketching and wireframes, especially useful in conversation with other team members to illustrate your ideas visually and collaborate in a medium which does not privilege words.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Excalidraw`}</strong></p>
          <p>{`Open-source, collaborative, e2e encrypted sketching app. Need we say more?`}</p>
          <p><em parentName="p">{`H/T Adrian le Bas`}</em></p>
          <Button to="https://excalidraw.com/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Explore
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Machinations`}</strong></p>
          <p>{`A tool intended for game designers, useful for mechanism design and token economics.`}</p>
          <p><em parentName="p">{`H/T James Young`}</em></p>
          <Button to="https://machinations.io/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Mechanise
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Balsamiq`}</strong></p>
          <p>{`Rapid, effective and fun wireframing software.`}</p>
          <Button to="https://balsamiq.com/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Wireframe
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Whimsical`}</strong></p>
          <p>{`A visual workspace. You can build more beautiful flow charts more easily in here than in Diagrams.net, though perhaps not quite as extensive or complicated.`}</p>
          <p><em parentName="p">{`H/T Nazzareno Massari`}</em></p>
          <Button to="https://whimsical.com/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Visualise
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`sketch.systems`}</strong></p>
          <p>{`A tool for prototyping state machines. Useful for whiteboarding sessions and iterating quickly with designs which you can play with in real time.`}</p>
          <p><em parentName="p">{`H/T Mike Ryan`}</em></p>
          <Button to="https://sketch.systems/Linknon/sketch/new" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Sketch
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Loom`}</strong></p>
          <p>{`The expressiveness of video with the convenience of messaging. Easily linked to GitHub. Great for code review and collaborative design.`}</p>
          <p><em parentName="p">{`H/T Jo-Ann Hamilton`}</em>{`.`}</p>
          <Button to="https://www.loom.com/use-cases/engineering" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Record
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "system-design",
      "style": {
        "position": "relative"
      }
    }}>{`System Design`}<a parentName="h2" {...{
        "href": "#system-design",
        "aria-label": "system design permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Building the one design system for all web3 components has been a long-time dream for many different people in web3. After all, if everything is modular and interoperable, shouldn't we just be able to build a best practices/standard library for critical components - connecting your wallet, signing transactions, displaying transaction statuses etc. - that everyone can reuse? `}</p>
    <p>{`This sounds eminently reasonable, and like many similarly reasonable ideas has been more difficult to realise in practice - see the now-deprecated Lorikeet (Aragon) and Rimble (Consensys) for proof. However, we present the design system used by this site as one live example of an approach which may be more long-lived. `}</p>
    <p>{`MakerDAO built it in order to maintain a coherent visual language across the Maker Foundation's assets and community-run sites, while enabling key differences that (i) made it clear which sites and documents were run by whom and (ii) to keep from running afoul of legal regulations to which the Foundation had to adhere. It therefore has a great reason for being, is well thought-out and very well engineered.`}</p>
    <Column mdxType="Column">
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column',
        margin: '20px 0px'
      }} mdxType="Flex">
        <p><strong parentName="p">{`DAI-UI`}</strong></p>
        <p>{`Though in early stages, there are some useful recipes here. It also uses theme-ui, so check out that library for all your normal design needs, especially if you're building in React.`}</p>
        <Button to="https://design-system.mkr-js-prod.now.sh/recipes" mdxType="Button">
    Craft
        </Button>
      </Flex>
    </Column>
    <h2 {...{
      "id": "space-design",
      "style": {
        "position": "relative"
      }
    }}>{`Space Design`}<a parentName="h2" {...{
        "href": "#space-design",
        "aria-label": "space design permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`All our resources are intended to reveal the interdisciplinary nature of work in web3. Therefore, before you actually begin coding, we think you should explore the full design space by thinking very carefully about the `}<strong parentName="p">{`deep why`}</strong>{`. Why are you building this particular product as opposed to any other thing? When you find what you think is an answer, ask yourself the question again and keep pushing for ever deeper insights into your actual motivations and intentions. We promise that this will help you write much less code, and it will be much more awesome.`}</p>
    <Column mdxType="Column">
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column',
        margin: '20px 0px'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Hypersprint`}</strong></p>
        <p>{`Based on the insightful design book of the same name, this is a Miro template that you can fork and use to quickly iterate over new ideas with your own team. We use it for Kernel and recommend the process for identifying the most impactful practical work your team can undertake.`}</p>
        <Button to="https://miro.com/miroverse/category/workshops/hypersprint" mdxType="Button">
    Consider
        </Button>
      </Flex>
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column',
        margin: '20px 0px'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Design Tools for Developers`}</strong></p>
        <p>{`Now that you have thought through your deep why, you'll need some easy prototyping tools iterating over any user feedback you may have gathered. This list of tools is as complete as any we know and will lead you to many interesting resources. The list is limited to free tools you can use right away.`}</p>
        <Button to="https://github.com/bradtraversy/design-resources-for-developers" mdxType="Button">
    Begin
        </Button>
      </Flex>
    </Column>
    <p>{`We have included a recording of Andrej describing the Hypersprint process in case you find the Miro template linked above a bit confusing at first. The main point is to keep moving, play great music, and have fun.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/GgPfP_3cR9U?start=137" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      